<template>
  <div id="collagedot">
    <top :name="text" />
    <!-- 商品详情轮播 -->
    <div class="lunbo_div">
      <van-swipe class="my-swipe" :autoplay="2000" indicator-color="gray">
        <van-swipe-item v-for="(item, index) in images" :key="index">
          <img alt="cover" :src="item" class="lunbo" />
        </van-swipe-item>
      </van-swipe>
      <div class="posi">
        <p style="font-size: 0.36rem; display: flex; align-items: center">
          ￥{{ data.display_price }}
          <span style="color: white; font-size: 0.2rem; margin-left: 0.2rem"
            >{{ data.comfort_amount }}元安慰奖</span
          >
        </p>
        <div>
          <img src="../assets/fenxiang.png" alt="" />
          <p>分享</p>
        </div>
      </div>
    </div>
    <div
      style="
        background: white;
        margin-bottom: 0.03rem;
        padding: 0.2rem 0 0.2rem 0;
      "
    >
      <p style="font-weight: bold" class="p">
        <span
          style="
            padding: 0.02rem 0.1rem 0.02rem 0.1rem;
            border: 1px solid red;
            color: red;
          "
          >{{ data.count_user }}人团</span
        >
        {{ data.group_name }}
      </p>
      <p style="color: gray" class="p">已拼数：{{ data.sales_volume }}</p>
    </div>
    <!-- <div class="buzhou" style="margin: 0.1rem auto">
      <el-steps :active="0" align-center>
        <el-step title="参与拼团" description="人成团"></el-step>
        <el-step title="3人拼中发货" description="7人未中退款"></el-step>
        <el-step title="未拼中补贴" description="3.00元安慰奖"></el-step>
      </el-steps>
    </div> -->
    <!-- <div class="fahuo" @click="showBase = true">
      <p style="color: gray; font-size: 0.28rem">已选规格</p>
      <p>华为官方至尊专享版 1/件</p>
      <p
        style="color: gainsboro; text-align: right; flex: 1; font-size: 0.4rem"
      >
        >
      </p>
    </div> -->
    <div class="fahuo" style="display: block">
      <p
        style="
          color: black;
          font-size: 0.28rem;
          font-weight: 900;
          display: block;
          border-bottom: 1px solid #dfd0d0;
          padding-bottom: 0.1rem;
        "
      >
        购买须知
      </p>
      <div style="margin-top: 0.2rem; line-height: 0.42rem">
        <p style="color: red">Step one</p>

        <p>挑选喜欢的商品，发起拼团或者参团</p>

        <p style="color: red">Step two</p>

        <p>邀请好友参与拼团，拼团成功</p>

        <p style="color: red">Step three</p>

        <p>系统抽取指定人数获得商品，其余团员者会退款并瓜分部分现金红包</p>

        <p style="color: red">！关于中奖声明</p>

        <p>
          抽到商品的参团者，72小时内会安排发货，发货延迟的情况包括但不限于恶劣天气等原因，请耐心等待，如有任何问题或者合理的特殊要求，请联系客服进行沟通解决
        </p>

        <p style="color: red">！关于中奖算法</p>

        <p>
          系统会在拼团成功后10分钟开奖，会取当前时间÷团购人数÷中奖人数，除到数字的长度等同于团购人数为止，位数不足系统随机补充，得到后的数字随机抽取一位，或者从1-团购人数随机抽取一位，后者优先级大于前者,后面会使用号码牌进行开奖
        </p>
      </div>
    </div>
    <van-divider :style="{ borderColor: 'rgb(219 219 219)' }"
      >商品详情</van-divider
    >
    <div class="xiangqing_img">
      <img
        v-for="(item, index) in data.detailed_picture"
        :key="index"
        :src="item"
        alt=""
      />
    </div>
    <!-- 商品规格 -->
    <!--  sku   sku 数据 -->
    <!--  goods 商品信息 -->
    <!--  goods-id   商品 id -->
    <!--  quota 商品信息 -->
    <!--  quota-used   已经购买过的数量 -->
    <!--  hide-stock 	是否显示商品剩余库存 -->
    <!--  message-config   留言相关配置 -->

    <van-sku
      v-model="showBase"
      :sku="skuData.sku"
      :goods="skuData.goods_info"
      :goods-id="skuData.goods_id"
      :hide-stock="kuchun_istrue"
      :quota="skuData.quota"
      :quota-used="skuData.quota_used"
      :initial-sku="initialSku"
      :show-add-cart-btn="is_gouwu"
      reset-stepper-on-hide
      reset-selected-sku-on-hide
      disable-stepper-input
      :close-on-click-overlay="closeOnClickOverlay"
      :custom-sku-validator="customSkuValidator"
      @buy-clicked="onBuyClicked"
      :buy-text="pintuan"
    >
      <template #sku-header-price="props">
        <div class="van-sku__goods-price">
          <span class="van-sku__price-symbol">￥</span
          ><span class="van-sku__price-num">{{
            Number(props.price * 100)
          }}</span>
        </div>
      </template>
    </van-sku>

    <!-- 底部购买 -->
    <van-goods-action style="z-index: 9">
      <van-goods-action-icon
        icon="chat-o"
        text="客服"
        color="#ee0a24"
        @click="kefu"
      />
      <van-goods-action-icon icon="cart-o" text="购物车" />
      <van-goods-action-icon icon="shop-o" text="店铺" />
      <van-goods-action-button
        type="danger"
        text="立刻开团"
        @click="showBase = true"
      />
    </van-goods-action>
  </div>
</template>

<script>
import Vue from "vue";
import top from "../components/filltop.vue";
import skuData from "../js/data.js";
export default {
  components: {
    top,
  },
  data() {
    return {
      text: "拼团详情",
      images: [],
      skuData: skuData,
      is_gouwu: false,
      showBase: false,
      showCustom: false,
      kuchun_istrue: true,
      showStepper: false,
      showSoldout: false,
      closeOnClickOverlay: true,
      initialSku: {
        s1: "30349",
        s2: "1193",
        selectedNum: 3,
      },
      customSkuValidator: () => "请完善商品规格信息!",
      pintuan: "立刻开团",
      data: {},
    };
  },
  // 从index页面的拼团购进入以及拼团猎豹进入刷新数据
  beforeRouteEnter(to, from, next) {
    console.log(to, "to"), console.log(from, "from");
    next((vm) => {
      if (from.name == "index" || from.name == "collagenumber") {
        // 列表传输的数据
        vm.data = vm.$route.params.success;
        vm.data.json_template = JSON.parse(vm.data.json_template);
        vm.data.rotation = JSON.parse(vm.data.rotation);
        vm.data.detailed_picture = JSON.parse(vm.data.detailed_picture);
        // 列表传输的数据 End
        // 商品规格 sku
        vm.skuData = vm.data.json_template.secifications;
        // 商品轮播图片
        vm.images = vm.data.rotation;
      }
      if (from.name == "submission") {
        // 1
      }
    });
  },
  methods: {
    onBuyClicked(data) {
      console.log(data);
      var bt = "";
      for (var i = 0; i < 6; i++) {
        if (data.selectedSkuComb[i] == undefined) {
          // 未定义  跳出循环
          break;
        } else {
          console.log(data.selectedSkuComb[i])
          // 有数据  拼接字符串
          bt = bt + data.selectedSkuComb[i] + ",";
        }
      }
      // 立即购买
      console.log(data);
      this.$https(
        "collage/getfrom",
        "id=" +
          sessionStorage.getItem("user_id") +
          "&phone=" +
          sessionStorage.getItem("user_phone") +
          "&collage=" +
          this.data.id +
          "&attrid=" +
          bt +
          "&commodity_id=" +
          data.selectedSkuComb.id +
          "&skunum=" +
          data.selectedNum,
        "post"
      ).then((res) => {
        if (res.data.code == "000000" && res.data.data.length != 0) {
          // 缓存请求参数 预订界面要使用
          sessionStorage.setItem("commodity_id", this.data.id);
          sessionStorage.setItem("attrid", bt);
          sessionStorage.setItem("shangpid", data.selectedSkuComb.id);
          this.$router.push({
            name: "submission",
            params: { bayget: JSON.stringify(res.data.data) },
          });
        }
      });
      // this.$toast("buy:" + JSON.stringify(data));
      // console.log(JSON.stringify(data));
      // this.$router.push({
      //   path: "/further/submission",
      //   query: { setid: 123456 },
      // });
    },
    onAddCartClicked(data) {
      // 加入购物车
      this.$toast("add cart:" + JSON.stringify(data));
    },
    kefu() {
      // 客服
    },
  },
  mounted() {
    // // 列表传输的数据
    // this.data = this.$route.params.success;
    // this.data.json_template = JSON.parse(this.data.json_template);
    // this.data.rotation = JSON.parse(this.data.rotation);
    // // 列表传输的数据 End
    // // 商品规格 sku
    // this.skuData = this.data.json_template.secifications;
    // // 商品轮播图片
    // this.images = this.data.rotation;
  },
};
</script>

<style scoped>
.buzhou {
  width: 100%;
  height: auto;
  margin-bottom: 0.01rem;
  background: white;
  padding: 0.2rem;
  margin: 0.1rem auto;
  font-size: 0.26rem;
}
.posi {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: auto;
  padding: 0.06rem 0.2rem 0 0.2rem;
  background: #008acb;
  display: flex;
  font-size: 0.26rem;
  align-items: center;
  justify-content: space-between;
  color: white;
}
.posi div img {
  width: 0.4rem;
  height: auto;
}
#collagedot {
  background: #efefef;
}
.jl {
  width: 94%;

  background: #2a5cbe63;
  color: #550b61;
}
.p {
  font-size: 0.28rem;
  padding: 0 0.2rem 0 0.2rem;
  margin-bottom: 0.2rem;
}
.lunbo_div {
  width: 100%;
  height: auto;
  position: relative;
}
.lunbo {
  width: 100%;
  height: auto;
  background-size: cover;
  background-position: center center;
}
.fahuo {
  width: 100%;
  display: flex;
  height: auto;
  margin-bottom: 0.01rem;
  background: white;
  align-content: center;
  padding: 0.2rem;
  font-size: 0.26rem;
}
.fahuo :nth-child(1) {
  margin-right: 0.2rem;
}
.xiangqing_img {
  width: 100%;
  height: auto;
}
.xiangqing_img img {
  width: 100%;
  height: auto;
}
</style>